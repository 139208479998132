import React, { useState } from "react";
import QRCode from "react-qr-code";
import axios from "axios";

import "./App.css";

function App() {
  const [userId1, setUserId1] = useState("");
  const [serviceId1, setServiceId1] = useState("");

  const [userId2, setUserId2] = useState("");
  const [serviceId2, setServiceId2] = useState("");
  const [operation, setOperation] = useState("");
  const [qrCodeData, setQRCodeData] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [messageStatus, setMessageStatus] = useState(null);

  const handleUserId1 = (event) => {
    setUserId1(event.target.value);
  };

  const handleServiceId1 = (event) => {
    setServiceId1(event.target.value);
  };

  const handleUserId2 = (event) => {
    setUserId2(event.target.value);
  };

  const handleServiceId2 = (event) => {
    setServiceId2(event.target.value);
  };

  const handleOperation = (event) => {
    setOperation(event.target.value);
  };

  // Set the request headers
  const config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_APIKEY}`,
      "Content-Type": "application/json",
    },
  };

  const handleSubmit = async () => {
    const userId = parseInt(userId1);
    const serviceId = parseInt(serviceId1);

    if (userId === "" || serviceId === "") {
      setRegistrationStatus("Set User ID and Service ID!");
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/user/subscribe-service",
          { userId: userId, serviceId: serviceId },
          config
        );

        console.log(response.data);

        if (response.data.success) {
          setRegistrationStatus("Service registration successful!");
          setQRCodeData(response.data.qrCodeData);
        } else {
          setRegistrationStatus(response.data.status);
        }
      } catch (error) {
        console.error("Error:", error);
        setRegistrationStatus("An error occurred during registration.");
      }
    }
  };

  // Function to send a message to the server via Axios
  const executeAuthOperation = async () => {
    const userId = parseInt(userId2);
    const serviceId = parseInt(serviceId2);

    if (userId === "" || serviceId === "" || operation === "") {
      setRegistrationStatus("Set User ID, Service ID and Operation Type!");
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/user/auth-operation",
          { userId: userId, serviceId: serviceId, operation: operation },
          config
        );

        console.log(response.data);

        if (response.data.success) {
          setMessageStatus("Message sent successfully!");
        } else {
          setMessageStatus(response.data.status);
        }
      } catch (error) {
        console.error("Error:", error);
        setMessageStatus("Failed to send message.");
      }
    }
  };

  return (
    <div className="App">
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, width: 500 }}>
          <h1>1. Service Registration</h1>
          <p>Enter User ID (integer) and Service ID (integer) before generating QR Code</p>
          <input
            type="text"
            value={userId1}
            onChange={handleUserId1}
            placeholder="User ID"
            style={{ marginRight: 10, width: 100 }}
          />
          <input
            type="text"
            value={serviceId1}
            onChange={handleServiceId1}
            placeholder="Service ID"
            style={{ width: 100 }}
          />
          <br />
          <br />
          <button onClick={handleSubmit}>Generate QR Code</button> <br />
          {qrCodeData && (
            <div>
              <h2>Scan this QR Code:</h2>
              <QRCode value={qrCodeData} />
            </div>
          )}
          {registrationStatus && <p>{registrationStatus}</p>}
        </div>
        <div style={{ flex: 1, width: 500 }}>
          <h1>2. Auth Operation</h1>
          <p>
            Enter User ID (integer), Service ID (integer) and Operation Type (string) to send a push
            notification to the user device.
          </p>
          <input
            type="text"
            value={userId2}
            onChange={handleUserId2}
            placeholder="User ID"
            style={{ marginRight: 10, width: 100 }}
          />
          <input
            type="text"
            value={serviceId2}
            onChange={handleServiceId2}
            placeholder="Service ID"
            style={{ marginRight: 10, width: 100 }}
          />
          <input
            type="text"
            value={operation}
            onChange={handleOperation}
            placeholder="Operation Type"
            style={{ width: 100 }}
          />
          <br />
          <br />
          <button onClick={executeAuthOperation}>Execute Auth Operation</button>
          {messageStatus && <p>{messageStatus}</p>}
        </div>{" "}
      </div>
    </div>
  );
}

export default App;
